import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faBriefcase,
	faGraduationCap
} from '@fortawesome/free-solid-svg-icons';

interface Accomplishment {
	accomplishment: string;
}

interface ResumeProps {
	role: string;
	company: string;
	date: string;
	accomplishments: Accomplishment[];
	isSchool: boolean;
}

const ResumeBox: React.FC<ResumeProps> = ({
	role,
	company,
	date,
	accomplishments,
	isSchool
}) => {
	return (
		<div className="col-lg-6 m-15px-tb">
			<div className="resume-box">
				<ul>
					<li>
						<div className="icon">
							{isSchool ? (
								<FontAwesomeIcon icon={faGraduationCap} fixedWidth />
							) : (
								<FontAwesomeIcon icon={faBriefcase} fixedWidth />
							)}
						</div>
						<span className="time open-sans-font text-uppercase">{date}</span>
						<h5 className="poppins-font text-uppercase">
							{role}
							<br />
							<span className="place open-sans-font">- {company}</span>
						</h5>
						{accomplishments.length > 0 && (
							<p className="open-sans-font">
								{accomplishments.map((item, index) => (
									<React.Fragment key={index}>
										{item.accomplishment}
										<br />
									</React.Fragment>
								))}
							</p>
						)}
					</li>
				</ul>
			</div>
		</div>
	);
};

export default ResumeBox;
