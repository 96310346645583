import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import ResumeBox from './resumeBox';

interface ResumeEntryMetadata {
	company: string;
	date: string;
	id: number;
	isschool: boolean;
	role: string;
	accomplishments: {
		accomplishment: string;
	}[];
}

interface ResumeEntry {
	node: {
		metadata: ResumeEntryMetadata;
	};
}

const AboutComponent = () => {
	// GraphQL query to fetch data from Cosmic JS
	const resumeQueryData = useStaticQuery(graphql`
		query {
			allCosmicjsResumeentries(sort: { fields: [metadata___id], order: ASC }) {
				edges {
					node {
						metadata {
							company
							date
							id
							isschool
							role
							accomplishments {
								accomplishment
							}
						}
					}
				}
			}
			allCosmicjsAboutbio {
				edges {
					node {
						metadata {
							bio
						}
					}
				}
			}
		}
	`);

	const resumeData = resumeQueryData.allCosmicjsResumeentries.edges.map(
		(edge: ResumeEntry) => edge.node.metadata
	);

	const bioData =
		resumeQueryData.allCosmicjsAboutbio.edges[0].node.metadata.bio;

	const formattedBioData = bioData.split('\n\n');

	return (
		<div className="AboutComponent">
			{/* Page Title */}
			<div className="title-section text-left text-sm-center">
				<h1>
					About <span className="main-color">Matt Murphy</span>
				</h1>
			</div>
			<div className="container">
				{/* Bio */}
				<div className="row pl-50px-sm">
					{/* Bio Text */}
					<div className="col-md-6 pl-sm-5">
						{formattedBioData.map((text: string, i: number) => (
							<p key={i}>{text}</p>
						))}
					</div>
					{/* Bio Photo */}
					<div className="col-md-6">
						<StaticImage
							src="../../static/images/MM_About_New.jpg"
							alt="Matt Headshot"
							className="img-fluid about-img pl-none pl-md-3"
						/>
					</div>
				</div>

				<hr className="separator mt-5" />

				{/* Experience & Education */}
				<div className="row">
					<div className="col-12">
						<h3 className="text-uppercase pb-2 mb-3 text-center">
							Experience & Education
						</h3>
					</div>
					{resumeData.map((item: ResumeEntryMetadata) => (
						<ResumeBox
							key={item.id}
							role={item.role}
							company={item.company}
							date={item.date}
							accomplishments={item.accomplishments}
							isSchool={item.isschool}
						/>
					))}
				</div>
			</div>
		</div>
	);
};

export default AboutComponent;
